<template>
  <el-card
    class="text-left"
    shadow="never"
  >
    <el-row :gutter="20">
      <el-col :span="3">
        <el-button
          type="primary"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-col>
      <el-col :span="17">
        <el-form
          :inline="true"
          label-width="80px"
        >
          <el-form-item>
            <el-input
              v-model="searchKeyword"
              placeholder="请输入关键字"
              suffix-icon="el-icon-search"
            />
          </el-form-item>
          <el-form-item label="平台">
            <el-select
              v-model="selectDeviceKey"
              placeholder="请选择"
            >
              <el-option
                v-for="item in deviceOptions"
                :key="item.value"
                :label="item.label"
                :value="checkValue(item.value)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="版位">
            <el-select
              v-model="searchPositionId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in positionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleRefresh"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { promotePositionList } from '@/api/admin/promote'

export default {
  data () {
    return {
      positionList: []
    }
  },
  props: {
    keyword: {
      type: String,
      require: true
    },
    position_id: {
      type: String,
      require: true
    },
    device: {
      type: String,
      require: true
    },
    deviceOptions: {
      type: Array,
      require: true
    }
  },
  computed: {
    searchKeyword: {
      set (str) {
        this.handleQuery({
          keyword: str
        })
      },
      get () {
        return this.keyword
      }
    },
    searchPositionId: {
      set (str) {
        this.handleQuery({
          position_id: str
        })
      },
      get () {
        return this.position_id
      }
    },
    selectDeviceKey: {
      set (str) {
        this.handleQuery({
          device: str
        })
      },
      get () {
        return this.device
      }
    }
  },
  created () {
    this.getPositionList()
  },
  methods: {
    getPositionList () {
      promotePositionList({
        page: 1,
        pageSize: 1000
      }).then(res => {
        switch (res.status) {
          case resStatus.OK:
            const newArr = res.data.map(res => {
              return { value: res.id, label: res.name }
            })
            newArr.unshift({ value: '', label: '全部' })
            this.positionList = newArr
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    checkValue(val) {
      // API設計,device帶空值時,才會顯示所有資料。
      // 因為all是獨立的型態,有自己的資料
      if (val === 'all') {
        return ''
      } else {
        return val
      }
    },
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.handleQuery({
        page: 1
      })
      this.$emit('handleRefresh')
    },
    handleAdd () {
      this.$router.push('promote/add')
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0 !important;
}
.left-container {
  margin-right: 100px;
}
</style>
