<template>
  <div>
    <div v-if="isEditable">
      <router-view :deviceOptions="deviceOptions" />
    </div>
    <div v-else>

      <Head
        :keyword="query.keyword"
        :position_id="query.position_id"
        :device="query.device"
        :deviceOptions="deviceOptions"
        @handleQuery="handleQuery"
        @handleRefresh="handleRefresh"
      />
      <Table
        :tableData="pageData.data"
        :editable="editable"
        :removal="removal"
        @handleDelete="handleDelete"
        @handleRefresh="handleRefresh"
      />
      <Pagination
        :query="query"
        :pageData="pageData"
        @handleQuery="handleQuery"
        @handleRefresh="handleRefresh"
      />
      <el-dialog
        title="刪除提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <span>是否确认删除？</span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="checkDelete"
          >确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import resStatus from '@/constants/resStatus'
import { promoteList, promoteDelete } from '@/api/admin/promote'

import Head from './Head'
import Table from './Table'
import Pagination from '@/components/admin/pagination'

export default {
  components: {
    Head,
    Table,
    Pagination
  },
  data () {
    return {
      centerDialogVisible: false,
      query: {
        page: 1,
        pageSize: 10,
        keyword: '',
        position_id: '',
        device: ''
      },
      editable: true,
      removal: true,
      pageData: {
        data: []
      },
      isEditable: false,
      deleteId: '',
      deviceOptions: [{
        value: 'all',
        label: '全部'
      }, {
        value: 'web',
        label: 'PC网页(WEB)'
      }, {
        value: 'app',
        label: '手机应用程式(APP)'
      }]
    }
  },
  methods: {
    init () {
      const pageName = this.$route.name
      switch (pageName) {
        case 'AdminMarketPromoteAdd':
        case 'AdminMarketPromoteEdit':
          this.isEditable = true
          break
        default:
          this.isEditable = false
          promoteList(this.query).then(res => {
            switch (res.status) {
              case resStatus.OK:
                res.data = res.data.map(it => {
                  let deviceLabel = ''
                  if (it.device === 'all') {
                    deviceLabel = '全部'
                  } else if (it.device === 'web') {
                    deviceLabel = 'PC网页(WEB)'
                  } else if (it.device === 'app') {
                    deviceLabel = '手机应用程式(APP)'
                  }
                  return { ...it, ...{ device: deviceLabel } }
                })
                this.pageData = res
                break
              default:
                this.$message.error(res.message)
            }
          }).catch(err => {
            this.$message.error(err)
          })
          break
      }
    },
    handleQuery (obj) {
      forIn(obj, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
    },
    handleEditable (boo) {
      this.editable = boo
    },
    handleDelete (id) {
      this.centerDialogVisible = true
      this.deleteId = id
    },
    checkDelete () {
      this.centerDialogVisible = false
      promoteDelete(this.deleteId).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.handleRefresh()
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleRefresh () {
      this.init()
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  }
}
</script>
