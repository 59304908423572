import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

export function promoteList (params) {
  return request({
    url: '/api/console/promote',
    method: 'get',
    params: params
  })
}

export function promotePositionList (params) {
  return request({
    url: '/api/console/positions',
    method: 'get',
    params: params
  })
}

export function promoteDetail (id) {
  return request({
    url: `/api/console/promote/${id}`,
    method: 'get'
  })
}

export function promoteChangeStatus (id, status) {
  return request({
    url: `/api/console/promote/${id}`,
    method: 'get',
    params: {
      is_visible: status
    }
  })
}

export function promoteDelete (id) {
  return request({
    url: `/api/console/promote/${id}`,
    method: 'delete'
  })
}

export function promoteAdd (data) {
  return request({
    url: '/api/console/promote',
    method: 'post',
    data: getFormData(data)
  })
}

export function promoteEdit (id, data) {
  return request({
    url: `/api/console/promote/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}
